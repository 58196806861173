/*
 * @Author: liuzhenkun
 * @Date: 2018-07-10 17:04:30
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-10-09 16:26:58
 */

import YqgCookie from './yqg-cookie';

export const genWebDeviceToken = ({
    setCookieOptions
} = {}) => {
    if (YqgCookie.getDeviceToken()) {
        return YqgCookie.getDeviceToken();
    }

    const token = `webFakeToken-${Date.now()}`;
    YqgCookie.setDeviceToken(token, setCookieOptions);

    return token;
};

export const isOnLine = () => window.navigator.onLine;

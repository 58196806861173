/**
 * @Author: center
 * @Date: 2020/8/11-3:52 下午
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:12
 */

export default () => (phone, maskNum = 4, suffixNum) => {
    if (!phone) {
        return null;
    }

    const regStr = suffixNum ? `(^\\+?\\d{3})(\\d*)(\\d{${suffixNum}})` : `(^\\+?\\d{3})(\\d{1,${maskNum}})(\\d*)`;

    return phone.replace(new RegExp(regStr), (num, $1, $2, $3) => ($1 + $2.replace(/./g, '*') + $3));
};

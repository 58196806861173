/**
 * @author center
 * @date 2018/9/5-下午7:45
 * @file util
 */

let instanceCount = 0;

export const instanceMounted = () => (instanceCount += 1);
export const instanceDestroyed = () => (instanceCount -= 1);
export const hasInstance = () => instanceCount > 0;

/**
 * @Author: KylesLight
 * @Date: 15/05/2018-6:29 PM
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:08
 */
/* window, document */

const DEFAULT_HEADER_BREAK_POINT = 80;

export const scrollAcrossClass = (el, breakPoint, className) => {
    if (window) {
        if ((window.pageYOffset || document.scrollTop) > breakPoint) el.classList.add(className);

        window.addEventListener('scroll', () => {
            const scrollTop = window.pageYOffset || document.scrollTop;

            if (scrollTop > breakPoint) {
                el.classList.add(className);

                return;
            }

            el.classList.remove(className);
        });
    }
};

export const scrollAffixClass = (el, className, headerBreakPoint = DEFAULT_HEADER_BREAK_POINT) => {
    if (window) {
        const initScrollTop = window.pageYOffset || 0;
        const breakPoint = el.getBoundingClientRect().top + initScrollTop;

        if ((initScrollTop + headerBreakPoint) > breakPoint) el.classList.add(className);

        window.addEventListener('scroll', () => {
            const scrollTop = window.pageYOffset || document.scrollTop;

            if ((scrollTop + headerBreakPoint) > breakPoint) {
                el.classList.add(className);

                return;
            }

            el.classList.remove(className);
        });
    }
};

export const isTouchScreenFunc = () => {
    if (typeof window === 'undefined') return false;

    return 'ontouchend' in window.document;
};

export const isChildrenDOM = (parent, el) => parent.contains(el);

export const on = (el, event, handler) => {
    if (el && event && handler) {
        el.addEventListener(event, handler, false);
    }
};

export const off = (el, event, handler) => {
    if (el && event && handler) {
        el.removeEventListener(event, handler, false);
    }
};

/**
 * @Author: xinyiliang
 * @Date: 2019/3/26-11:23
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:12
 */

import ua from 'ssr-common/util/webview/ua-parsed';

const setElWidth = el => { // 屏幕尺寸发生改变的时候需要重新设置
    const elParent = el.parentElement;
    const {width, paddingRight, paddingLeft} = elParent.currentStyle
        ? elParent.currentStyle
        : getComputedStyle(elParent, false);
    const actualWidth = parseFloat(width, 10) - parseFloat(paddingRight, 10) - parseFloat(paddingLeft, 10);

    el.style.width = `${actualWidth}px`; // fixed后无法和父级宽度一样，这里需要设置一下
};

const adjustOverflow = el => {
    // 目录折叠时，auto有时候不大对，这里手动设置成visible
    el.style.overflowY = el.firstElementChild.scrollHeight <= el.style.maxHeight.slice(0, -2)
        ? 'visible'
        : 'auto';
};

const createStickyHandler = (event, el, options) => {
    const {
        topTarget,
        bottomTarget,
        setTop = false,
        topSpace = 60,
        bottomSpace = 60,
        contentTarget
    } = options;
    const documentEl = document.documentElement;
    const {clientHeight, scrollHeight} = documentEl;
    const scrollTop = documentEl.scrollTop || window.pageYOffset || document.body.scrollTop;
    const windowHeight = window.innerHeight;
    const elRectTop = el.getBoundingClientRect().top;
    const elHeight = el.getBoundingClientRect().height;
    const topHeight = document.querySelector(topTarget).offsetHeight;
    const bottomHeight = document.querySelector(bottomTarget).offsetHeight;

    const topElBottom = document.querySelector(topTarget).getBoundingClientRect().bottom;
    const bottomElTop = document.querySelector(bottomTarget).getBoundingClientRect().top;
    const contentLeft = (contentTarget && document.querySelector(contentTarget).getBoundingClientRect().left) || 0;

    if ((elRectTop <= topHeight + topSpace) && (!setTop || (topElBottom < bottomSpace))) {
        // 底部元素是否出现
        const bottomDistance = scrollHeight - clientHeight - scrollTop;
        let maxHeight = windowHeight - topHeight - topSpace * 2;

        if (setTop) maxHeight = windowHeight - topSpace;

        const isBottomShow = bottomDistance < bottomHeight;

        if (isBottomShow && !setTop) maxHeight -= (bottomHeight - bottomDistance);// 底部元素出现

        if (setTop && ((bottomElTop - bottomSpace - topSpace) <= elHeight)) {
            el.style.position = 'absolute';
            el.style.bottom = '0px';
            el.style.left = '0px';
            el.style.top = 'unset';
        } else {
            el.style.position = 'fixed';

            if (setTop) {
                el.style.top = `${topSpace}px`;
                el.style.left = `${contentLeft}px`;
                el.style.bottom = 'unset';
            }
        }

        if (maxHeight <= 0) {
            el.style.visibility = 'hidden';
        } else {
            el.style.visibility = 'visible';
            el.style.maxHeight = `${maxHeight}px`;
        }
    } else {
        el.style.position = 'static';
    }

    adjustOverflow(el);
};

const resize = (event, el, value) => {
    setElWidth(el);
    createStickyHandler(event, el, value);
};

export default {
    inserted(el, binding) {
        if (ua.isWebview || (ua.isWechat && binding.value.hideBar) || ua.isComplaint) {
            return;
        }

        if (el['@@stickyHandler']) {
            window.removeEventListener('load', el['@@stickyHandler']);
            window.removeEventListener('resize', el['@@stickyHandlerResize']);
            window.removeEventListener('scroll', el['@@stickyHandler']);
        }

        el['@@stickyHandler'] = event => createStickyHandler(event, el, binding.value);
        el['@@stickyHandlerResize'] = event => resize(event, el, binding.value);

        el['@@stickyHandlerResize'](); // 只能通过调两次来拿到页面正确的高度
        window.addEventListener('load', el['@@stickyHandler']); // 确保能拿到正确的document.documentElement.scrollHeight
        window.addEventListener('resize', el['@@stickyHandlerResize']);
        window.addEventListener('scroll', el['@@stickyHandler']);
    },

    componentUpdated(el) { // 目录折叠时
        adjustOverflow(el);
    },

    unbind(el) {
        window.removeEventListener('load', el['@@stickyHandler']);
        window.removeEventListener('resize', el['@@stickyHandlerResize']);
        window.removeEventListener('scroll', el['@@stickyHandler']);
    }
};

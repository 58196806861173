/*
 * @Author: KylesLight
 * @Date: 2017-12-15 18:54:59
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-01-19 17:14:49
 */

import Vue from 'vue';
import VueRouter from 'vue-router';

import component from './component';
import directive from './directive';
import filter from './filter';
import './vue-error-handler';

Vue.use(component);
Vue.use(directive);
Vue.use(filter);

export {createVueRouter} from './router';
export {VueRouter};

export default Vue;

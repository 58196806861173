/**
 * @author panezhang
 * @date 2018/8/10-17:24
 * @file directive
 */

import clickOutside from './click-outside';
import preventScroll from './prevent-scroll';
import reachShow from './reach-show';
import sticky from './sticky';

export default {

    install(Vue) {
        Vue.directive('click-outside', clickOutside);
        Vue.directive('prevent-scroll', preventScroll);
        Vue.directive('reach-show', reachShow);
        Vue.directive('sticky', sticky);
    }

};

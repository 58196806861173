/**
 * @Author: center
 * @Date: 2018/7/19-下午4:41
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 16:14:30
 */

export const numberWithCommas = x => {
    if (!x && x !== 0 && x !== '0') return '';

    return x.toString().replace(/(^|\s)\d+/g, num => num.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
};

export const numberWithCommasFixed2 = x => (
    (x === +x) ? numberWithCommas((+x).toFixed(2)) : x
);

// eslint-disable-next-line max-len
export const numberWithUnit = number => ((typeof number === 'number' && (`${number}`).length > 4) ? (`${number}`).replace(/\d{4}$/g, '万') : number);

export const numberWithUnitFixed1 = num => {
    let number = num;
    number = parseInt(number, 10);

    if (number <= 999) return number;

    let result = (number / 10000).toFixed(1);
    const [integer, decimal] = result.split('.');

    result = decimal === '0' ? integer : result;

    return `${result}万`;
};

export const readerNumberWithUnit = number => {
    let res = number;

    if (number >= 10000) {
        const integer = Math.trunc(number / 10000);
        let decimal = (number % 10000) / 1000;

        if (!Number.isInteger(decimal)) {
            decimal = Math.trunc(decimal) + 1;
        }

        res = `${integer + decimal / 10}万`;
    }

    return res;
};

export const numberWithoutSpace = num => {
    if (!num) return '';

    return (`${num}`).replace(/\s/g, '');
};

export const bankNumberWithSpace = num => {
    if (!num) return '';

    const newNum = numberWithoutSpace(num);

    return newNum.replace(/(\d{4})(?=\d)/g, '$1 ');
};

export const phoneNumberWithSpace = num => {
    if (!num) return '';

    const newNum = numberWithoutSpace(num);

    return newNum.replace(/(\d{3})\s?(\d{0,4})\s?(\d{0,4})\d*/, '$1 $2 $3').trimEnd();
};

// 手机号4到7位掩码
export const phoneNumberWithMask4To7 = num => {
    if (!num) return '';

    const newNum = numberWithoutSpace(num);

    return newNum.replace(/(\d{3})\s?(\d{0,4})\s?(\d{0,4})\d*/, '$1****$3').trimEnd();
};

export const identityNumberWithSpace = num => {
    if (!num) return '';

    const newNum = numberWithoutSpace(num);

    return newNum.replace(/(\d{6})\s?(\d{0,4})\s?(\d{0,4})\s?(\d{0,4})\d*/, '$1 $2 $3 $4').trimEnd();
};

export const bankNumberFilter = {
    filter: num => bankNumberWithSpace(num),

    reverseFilter: num => num.replace(/\s+/g, '')
};

// 判断 url 是否为绝对路径的 url（带上完整的 协议://
export const isAbsUrl = url => /^(\w+):\/\//.test(url);

/*
 * @Author: liuzhenkun
 * @Date: 2018-07-10 11:37:57
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-06-03 15:49:10
 */

/* eslint-disable no-underscore-dangle */

// globals window

import {isServer} from 'ssr-common/constant/config';

export const isMobile = ua => /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(ua);

export const isAndroid = ua => /android/i.test(ua);

export const isIOS = ua => /iphone|ipad|ipod/i.test(ua);

export const isWechatMiniProgram = ua => (/miniProgram/i.test(ua))
    || (!isServer() && window && window.__wxjs_environment === 'miniprogram');

export const isWechat = ua => /micromessenger/i.test(ua) && !/wxwork/i.test(ua) && !isWechatMiniProgram(ua);

export const isWechatWork = ua => /micromessenger/i.test(ua) && /wxwork/i.test(ua) && !isWechatMiniProgram(ua);

export const isTikTok = ua => /aweme/i.test(ua);

export const isKwai = ua => /kwai/i.test(ua);

export const isYqg = ua => /yqg\//i.test(ua);

export const isYqd = ua => /yqd\//i.test(ua);

export const isZebra = ua => /zebra\//i.test(ua);

export const isEasyCash = ua => /yqdEasyCash\//i.test(ua);

export const isEasyCashNative = ua => /yqdEasyCash\w+\//i.test(ua);

export const isEasyCashLenderNative = ua => /IDN_FIN\//i.test(ua);

export const isComplaint = ua => /^complaint/i.test(ua);

export const isYqdGranaFacil = ua => /yqdGranaFacil\//i.test(ua);

export const isYqdPesoAgad = ua => /YqdPesoAgad\//i.test(ua);

export const isYqdMabilis = ua => /YqdMabilis\//i.test(ua);

export const isThFincash = ua => /YqdTha\//i.test(ua);

export const isThMoneyMall = ua => /YqdMoneyMall\//i.test(ua);

export const isYqdEasyPLN = ua => /YqdEasyPLN\//i.test(ua);

export const isYqdCashKey = ua => /YqdCashKey\//i.test(ua);

export const isYqdCredKey = ua => /YqdCredkey\//i.test(ua);

export const isYqdCredMex = ua => /YqdCredmex\//i.test(ua);

export const isKoi = ua => /koi\//i.test(ua);

export const isBiz = ua => /bizloan\//i.test(ua);

export const isLeYun = ua => /leyun\//i.test(ua);

export const isHx = ua => /hx\//i.test(ua);

export const isXJQB = ua => /xjqb\//i.test(ua);

export const isTFXSH = ua => /tfxsh\//i.test(ua);

export const isOlacred = ua => /mxOlacred\//i.test(ua);

export const isVivacredit = ua => /mxVivacredit\//i.test(ua);

export const isMayacash = ua => /mxMayacash\//i.test(ua);

export const isMac = ua => /macintosh|mac os x/i.test(ua);

export const isWindows = ua => /windows/i.test(ua);

export const isWebview = ua =>
    isYqg(ua)
    || isYqd(ua)
    || isZebra(ua)
    || isEasyCash(ua)
    || isEasyCashNative(ua)
    || isEasyCashLenderNative(ua)
    || isYqdGranaFacil(ua)
    || isYqdPesoAgad(ua)
    || isYqdMabilis(ua)
    || isThFincash(ua)
    || isThMoneyMall(ua)
    || isYqdEasyPLN(ua)
    || isYqdCashKey(ua)
    || isYqdCredKey(ua)
    || isYqdCredMex(ua)
    || isKoi(ua)
    || isBiz(ua)
    || isLeYun(ua)
    || isHx(ua)
    || isXJQB(ua)
    || isTFXSH(ua)
    || isOlacred(ua)
    || isVivacredit(ua)
    || isMayacash(ua);

export const getVersionCode = ua => {
    // eslint-disable-next-line max-len
    const SDK_VERSION_REG = /(yqg|yqd|zebra|koi|yqdpesoagad|bizloan|idn_fin|yqdeasycash|mxOlacred|mxvivacredit|yqdCredmex|mxmayacash|YqdMabilis|YqdTha|YqdEasyPLN)\w*\/([\d.]+)/i;
    const matchResult = ua.match(SDK_VERSION_REG);
    const versionCode = matchResult && matchResult[2];

    if (!versionCode) return null;

    if (/\./.test(versionCode)) {
        return `${+versionCode.split('.').map(digit => digit.padStart(2, 0)).join('')}`;
    }

    return parseInt(versionCode, 10);
};

export const isEasyCashOppo = ua => {
    const versionCode = getVersionCode(ua);

    return /yqdEasyCash\w+\//i.test(ua) && versionCode >= 34211 && versionCode?.toString()?.endsWith('1');
};

export const minVersion = (targetVersion = 0) => {
    if (isServer()) return;

    return getVersionCode(window.navigator.userAgent) >= targetVersion; /* eslint-disable-line consistent-return */
};

export const iosVersion = userAgent => userAgent.match(/os\s+(\d+)/i)[1] - 0;

export const isHuaWeiChannel = userAgent => {
    if (!isAndroid(userAgent)) return false;
    const appInfo = /Yqg\/\w+ \(([^}]*)\)/.exec(userAgent);

    if (appInfo && appInfo.length) {
        const infoList = appInfo[1].replace(/\s+/g, '').split(';');
        const channel = infoList[infoList.length - 1];

        return /^huawei$/.test(channel);
    }

    return false;
};

export const isMexGooglePlayChannel = ua => /CREDMEX_GOOGLEPLAY_MX/i.test(ua);

export const isMexHuaweiChannel = ua => /credmex_huawei_mx/i.test(ua);

export const isMexXiaoMiChannel = ua => /credmex_xiaomi_mx/i.test(ua);

export const isMexAppStoreChannel = ua => /CREDMEX_APP_STORE/i.test(ua) || /CREDMEX_TEST/i.test(ua);

export const platformMatchers = {
    android: isAndroid,
    ios: isIOS,
    mac: isMac,
    windows: isWindows
};

export const getPlatformType = ua => {
    const [platformType] = Object.entries(platformMatchers).find(([platform, matcher]) => matcher(ua)) || [];

    return platformType || 'unknown';
};

export const parse = ua => ({
    isMobile: isMobile(ua),

    isAndroid: isAndroid(ua),
    isIOS: isIOS(ua),

    isWechat: isWechat(ua),
    isWechatWork: isWechatWork(ua),
    isWechatMiniProgram: isWechatMiniProgram(ua),

    isTikTok: isTikTok(ua),
    isKwai: isKwai(ua),

    isYqg: isYqg(ua),
    isYqd: isYqd(ua),
    isZebra: isZebra(ua),
    isEasyCash: isEasyCash(ua),
    isEasyCashNative: isEasyCashNative(ua),
    isEasyCashLenderNative: isEasyCashLenderNative(ua),
    isYqdGranaFacil: isYqdGranaFacil(ua),
    isYqdPesoAgad: isYqdPesoAgad(ua),
    isYqdMabilis: isYqdMabilis(ua),
    isThFincash: isThFincash(ua),
    isThMoneyMall: isThMoneyMall(ua),
    isYqdEasyPLN: isYqdEasyPLN(ua),
    isYqdCashKey: isYqdCashKey(ua),
    isYqdCredKey: isYqdCredKey(ua),
    isYqdCredMex: isYqdCredMex(ua),
    isKoi: isKoi(ua),
    isBiz: isBiz(ua),
    isLeYun: isLeYun(ua),
    isHx: isHx(ua),
    isTFXSH: isTFXSH(ua),
    isOlacred: isOlacred(ua),
    isVivacredit: isVivacredit(ua),
    isMayacash: isMayacash(ua),

    isComplaint: isComplaint(ua), // 投诉宝

    isWebview: isWebview(ua),
    versionCode: getVersionCode(ua),

    isHuaWeiChannel: isHuaWeiChannel(ua),

    isMexGooglePlayChannel: isMexGooglePlayChannel(ua),
    isMexHuaweiChannel: isMexHuaweiChannel(ua),
    isMexXiaoMiChannel: isMexXiaoMiChannel(ua),
    isMexAppStoreChannel: isMexAppStoreChannel(ua),

    isMac: isMac(ua),
    isWindows: isWindows(ua)
});

/*
 * @Author: panezhang
 * @Date: 2018-08-10 17:23:17
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-01-15 15:04:51
 */

export default {
    install(Vue) {
        Vue.component('easemob', () => import('./easemob'));
        Vue.component('yqg-icon-collapse', () => import('./yqg-icon-collapse'));
        Vue.component('yqg-icon-nav', () => import('./yqg-icon-nav'));
        Vue.component('yqg-indicator', () => import('./yqg-indicator'));
        Vue.component('yqg-md-preview', () => import('./yqg-md-preview'));
        Vue.component('yqg-pdf-viewer', () => import('./yqg-pdf-viewer'));
        Vue.component('yqg-scroll-loading', () => import('./yqg-scroll-loading'));
        Vue.component('yqg-title', () => import('./yqg-title'));
        Vue.component('google-map', () => import('./google-map'));
    }
};

/**
 * @Author: panezhang
 * @Date: 2018/8/21-11:45
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-08-21 11:44:17
 */

import initAndroidWebViewBridge from 'ssr-common/vendor/android-webview-javascript-bridge';
import initIOSWebviewBridge from 'ssr-common/vendor/ios-webview-javascript-bridge';

import ua from './ua-parsed';

// 是否使用 webview bridge 通讯方式
export const USE_BRIDGE = ua.isIOS || (ua.isAndroid && (ua.isEasyCashNative || ua.isEasyCashLenderNative
    || ua.isYqdGranaFacil || ua.isYqdPesoAgad || ua.isYqdCashKey || ua.isYqdCredKey || ua.isYqdCredMex
    || ua.isTFXSH || ua.isOlacred || ua.isVivacredit || ua.isMayacash || ua.isYqdMabilis || ua.isThFincash
    || ua.isThMoneyMall || ua.isYqdEasyPLN));

if (ua.isWebview && USE_BRIDGE) {
    if (ua.isIOS) {
        initIOSWebviewBridge();
    } else {
        initAndroidWebViewBridge();
    }
}

let _bridge = null; // eslint-disable-line no-underscore-dangle

export const getWebviewBridge = () => new Promise((resolve, reject) => {
    if (_bridge) {
        resolve(_bridge);

        return;
    }

    if (USE_BRIDGE) {
        if ((_bridge = window.WebViewJavascriptBridge)) {
            _bridge.init();
            resolve(_bridge);

            return;
        }

        document.addEventListener(
            'WebViewJavascriptBridgeReady',

            event => {
                _bridge = event.bridge;
                _bridge.init();
                resolve(_bridge);
            },

            false
        );

        return;
    }

    if (ua.isAndroid && 'JavaScriptInterface' in window) {
        _bridge = {
            callHandler: (name, data, callback) => { // 暴露统一签名的接口
                const bridge = window.JavaScriptInterface;
                const result = (name in bridge) && (data !== undefined ? bridge[name](data) : bridge[name]());
                callback(result);
            }
        };

        resolve(_bridge);

        return;
    }

    reject();
});

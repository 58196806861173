/**
 * @Author: zhangpeng
 * @Date: 16/11/27-下午12:21
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-10-09 15:09:20
 */

// node_modules
import Cookie from 'js-cookie';

const KEYS = {
    USER_TOKEN: 'userToken',
    DEVICE_TOKEN: 'deviceToken',
    LENDER_TOKEN: 'lenderId'
};

export default {
    getUserToken() {
        return Cookie.get(KEYS.USER_TOKEN);
    },

    setUserToken(token) {
        Cookie.set(KEYS.USER_TOKEN, token);
    },

    clearUserToken() {
        Cookie.remove(KEYS.USER_TOKEN);
        Cookie.remove(KEYS.USER_TOKEN, {domain: window.document.domain});
    },

    clearLenderToken() {
        Cookie.remove(KEYS.LENDER_TOKEN);
        Cookie.remove(KEYS.LENDER_TOKEN, {domain: window.document.domain});
    },

    getDeviceToken() {
        return Cookie.get(KEYS.DEVICE_TOKEN);
    },

    setDeviceToken(token, options) {
        if (Object.prototype.toString.call(options) === '[object Object]') {
            Cookie.set(KEYS.DEVICE_TOKEN, token, options);

            return;
        }

        Cookie.set(KEYS.DEVICE_TOKEN, token);
    }
};

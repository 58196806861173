/**
 * @Author: panezhang
 * @Date: 2018/6/28-17:29
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 17:07:50
 */

import Router from 'vue-router';

const isRouteReady = route => Promise
    .all(route.matched.map(({instances = {}}) => {
        instances = instances.default || instances; // eslint-disable-line no-param-reassign

        return instances.scrollReady ? instances.scrollReady() : Promise.resolve();
    }));

export const createVueRouter = (options = {}) => {
    const router = new Router({
        mode: 'history',

        scrollBehavior: (to, from, savedPotion) => {
            if (!to.hash && !savedPotion) return {x: 0, y: 0}; // 不需要处理 scroll

            return isRouteReady(to)
                .then(() => (to.hash ? {selector: to.hash} : savedPotion));
        },

        ...options
    });

    if (typeof window !== 'undefined') {
        router.onReady(() => {
            const {currentRoute} = router;
            if (currentRoute && currentRoute.hash) {
                isRouteReady(currentRoute)
                    .then(() => {
                        const hashEl = document.querySelector(currentRoute.hash);
                        if (hashEl) {
                            window.scroll({
                                top: hashEl.offsetTop,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }
                    });
            }
        });
    }

    return router;
};

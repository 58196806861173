/**
 * @author panezhang
 * @date 2018/8/7-11:46
 * @file prevent-scroll
 */

const bodyStyle = {};
let top; // window scroll top

let modalNum = 0;

const usePreventScroll = () => {
    if (modalNum <= 0) { // only happens from 0 to 1
        top = window.scrollY;

        const {body} = document;
        Object.assign(bodyStyle, body.style);
        body.style.overflow = 'hidden';
        body.style.position = 'fixed';
        body.style.top = `${-top}px`;
        body.style.left = '0px';
        body.style.right = '0px';
    }

    modalNum += 1;
};

const removePreventScroll = () => {
    modalNum -= 1;

    try {
        if (modalNum <= 0) { // only happens from 1 to 0
            const {body} = document;
            body.removeAttribute('style');
            window.scrollTo(0, top);
            Object.assign(body.style, bodyStyle);
        }
    } catch (err) {
        // ignore
    }
};

export default {
    inserted(el, binding) {
        const bindingValue = binding.value;

        if ((bindingValue === undefined) || bindingValue.usePrevent) {
            usePreventScroll();
        }

    },

    update(el, binding) {
        // prevent-scroll所在的对象用的是v-show的时候用
        const {value, oldValue} = binding;

        if (value === undefined || value.usePrevent === oldValue.usePrevent) return;

        if (value.usePrevent) {
            usePreventScroll();
        } else {
            removePreventScroll();
        }
    },

    unbind() {
        removePreventScroll();
    }
};

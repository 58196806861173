/**
 * @author center
 * @date 2018/7/19-下午4:30
 * @file filter
 */

import {
    dateHyphen,
    dateDot,
    dateMonth,
    dateMonthHyphen,
    dateMinutesCn,
    dateSlash,
    dateMinutes,
    dateMinSlash,
    dateTime,
    dateWeekday,
    hourMinutes,
    dateCustomize,
    dateDayCn
} from 'ssr-common/util/date';

import {
    bankNumberWithSpace,
    numberWithoutSpace,
    numberWithCommas,
    numberWithCommasFixed2,
    numberWithUnit,
    numberWithUnitFixed1,
    readerNumberWithUnit,
    phoneNumberWithSpace,
    identityNumberWithSpace
} from 'ssr-common/util/string';

import phoneMaskFilter from './lib/phone-mask';

export default {

    install(Vue) {
        Vue.filter('yqgDateHyphen', dateHyphen);
        Vue.filter('yqgDateDot', dateDot);
        Vue.filter('yqgDateMonth', dateMonth);
        Vue.filter('yqgDateMonthHyphen', dateMonthHyphen);
        Vue.filter('yqgDateMinutesCn', dateMinutesCn);
        Vue.filter('yqgDateSlash', dateSlash);
        Vue.filter('yqgDateTime', dateTime);
        Vue.filter('yqgDateWeekday', dateWeekday);
        Vue.filter('yqgDateMinutes', dateMinutes);
        Vue.filter('yqgDateMinSlash', dateMinSlash);
        Vue.filter('yqgHourMinutes', hourMinutes);
        Vue.filter('yqgDateCustomize', dateCustomize);
        Vue.filter('yqgDateDayCn', dateDayCn);

        Vue.filter('yqgBankNumberWithSpace', bankNumberWithSpace);
        Vue.filter('yqgNumberWithoutSpace', numberWithoutSpace);
        Vue.filter('yqgNumberWithCommas', numberWithCommas);
        Vue.filter('yqgNumberWithCommasFixed2', numberWithCommasFixed2);
        Vue.filter('yqgNumberWithUnit', numberWithUnit);
        Vue.filter('yqgNumberWithUnitFixed1', numberWithUnitFixed1);
        Vue.filter('yqgPhoneNumberWithSpace', phoneNumberWithSpace);
        Vue.filter('yqgReaderNumberWithUnit', readerNumberWithUnit);
        Vue.filter('yqgIdentityNumberWithUnit', identityNumberWithSpace);

        Vue.filter('yqgNumCommas', numberWithCommas);
        Vue.filter('yqgNumCommasFixed', numberWithCommasFixed2);

        Vue.filter('yqgPhoneMask', phoneMaskFilter());
    }

};

/**
 * @Author: panezhang
 * @Date: 2018/8/15-17:12
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:12
 */

const TRACE_SSR = 'vue-ssr-trace';
const TRACE_CSR = 'vue-csr-trace';

function makeAttrTxt(item) {
    const attrArr = Object.keys(item)
        .map(key => `${key}="${item[key]}"`);
    attrArr.push(TRACE_SSR);

    return attrArr.join(' ');
}

function setAttrValues(el, {inner, ...attrs} = {}) {
    if (inner) {
        el.textContent = inner;
    }

    Object.keys(attrs)
        .forEach(key => el.setAttribute(key, attrs[key]));

    el.setAttribute(TRACE_CSR, true);
}

function find(elements, {inner, ...attrs}) {
    return elements.find(el => {
        if (inner && inner !== el.textContent) {
            return false;
        }

        return Object.keys(attrs).every(key => (el.getAttribute(key) === attrs[key]));
    });
}

// server render
function makeSSRHead(optoins) {
    const headArr = [];

    if (optoins.meta) {
        optoins.meta.forEach(item => headArr.push(`<meta ${makeAttrTxt(item)}>`));
    }

    if (optoins.link) {
        optoins.link.forEach(item => headArr.push(`<link ${makeAttrTxt(item)}>`));
    }

    if (optoins.style) {
        optoins.style.forEach(
            ({inner, ...attrs}) => headArr.push(`<style ${makeAttrTxt(attrs)}>${inner}</style>`)
        );
    }

    if (optoins.script) {
        optoins.script.forEach(
            ({inner, ...attrs}) => headArr.push(`<script ${makeAttrTxt(attrs)}>${inner}</script>`)
        );
    }

    return headArr.join('\n');
}

// after client render
function getSSRHeadElements() {
    return [...document.head.querySelectorAll(`[${TRACE_SSR}]`)];
}

function getCSRHeadElements() {
    return [...document.head.querySelectorAll(`[${TRACE_CSR}]`)];
}

// client
function makeCSRHead(options) {
    const oldElements = [...getSSRHeadElements(), ...getCSRHeadElements()];
    const newElements = [];
    const reuseElements = [];

    ['meta', 'link', 'style'].forEach(tagName => {
        if (options[tagName]) {
            options[tagName].forEach(item => {
                const oldElement = find(oldElements, item);
                if (oldElement) {
                    reuseElements.push(oldElement);
                    oldElements.splice(oldElements.indexOf(oldElement), 1);

                    return;
                }

                const el = document.createElement(tagName);
                setAttrValues(el, item);
                newElements.push(el);
            });
        }
    });

    return {newElements, oldElements, reuseElements};
}

function insert(elements) {
    if (elements) {
        elements.forEach(el => document.head.appendChild(el));
    }
}

function remove(elements) {
    if (elements) {
        elements.forEach(el => el.parentNode.removeChild(el));
    }
}

export const createServerMixin = () => ({
    created() { // 从钩子获取数据，解析生成 head，并设置到 ssrContext.head
        const vm = this;
        if (vm.$options && vm.$options.head) {
            vm.$ssrContext.head = makeSSRHead(vm.$options.head.call(vm));
        }
    }
});

export const createClientMixin = () => ({
    mounted() { // 从钩子获取数据，解析生成 head，并检查是否需要插入 head
        if (this.$options && this.$options.head) {
            const {newElements, oldElements} = makeCSRHead(this.$options.head.call(this));
            insert(newElements);
            remove(oldElements);
        }
    },

    updated() { // 从钩子获取数据，解析生成 head，并检查是否需要插入 head
        if (this.$options && this.$options.head) {
            const {newElements, oldElements} = makeCSRHead(this.$options.head.call(this));
            insert(newElements);
            remove(oldElements);
        }
    },

    beforeRouteLeave(to, from, next) { // 移除 head
        if (this.$options && this.$options.head) {
            remove([...getSSRHeadElements(), ...getCSRHeadElements()]);
        }

        next();
    }
});

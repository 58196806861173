/**
 * @Author: center
 * @Date: 2018/8/3-上午10:41
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:07
 */

import moment from 'moment';

const DateFormatMap = {
    dateHyphen: 'YYYY-MM-DD',
    dateDot: 'YYYY.MM.DD',
    dateMonth: 'YYYY年MM月DD日',
    dateMonthHyphen: 'YYYY-MM',
    dateMinutesCn: 'YYYY年MM月DD日 HH:mm',
    dateSlash: 'YYYY/MM/DD',
    dateTime: 'YYYY-MM-DD HH:mm:ss',
    dateMinutes: 'YYYY-MM-DD HH:mm',
    dateMinSlash: 'YYYY/MM/DD/HH:mm',
    hourMinutes: 'HH:mm',
    dayCn: 'MM月DD日'
};

export const MillisecondInDay = 24 * 60 * 60 * 1e3;

export const dateHyphen = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dateHyphen));

export const dateDot = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dateDot));

export const dateMonth = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dateMonth));

export const dateMonthHyphen = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dateMonthHyphen));

export const dateMinutesCn = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dateMinutesCn));

export const dateSlash = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dateSlash));

export const dateTime = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dateTime));

export const dateMinutes = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dateMinutes));

export const dateMinSlash = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dateMinSlash));

export const hourMinutes = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.hourMinutes));

export const dateDayCn = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.dayCn));

export const dateCustomize = (timeStamp, format = DateFormatMap.dateHyphen) => (
    !timeStamp ? '' : moment(timeStamp).format(format)
);

export const getDayStartTimeStamp = (timeStamp = Date.now()) => new Date(timeStamp).setHours(0, 0, 0, 0);

export const getDayEndTimeStamp = (timeStamp = Date.now()) => new Date(timeStamp).setHours(23, 59, 59, 999);

export const getDaysAgoTimeStamp = (days, timeStamp = Date.now()) => timeStamp - days * MillisecondInDay;

export const roundTimeToMonthStart = time => getDayStartTimeStamp(new Date(time).setDate(1));

export const roundTimeToMonthEnd = time => {
    const monthStart = roundTimeToMonthStart(time);
    const date = new Date(monthStart);

    return roundTimeToMonthStart(date.setMonth(date.getMonth() + 1)) - 1;
};

export const roundTimeToMonthRange = (time, monthRange) => {
    const date = new Date(time);
    const year = date.getFullYear();
    const month = date.getMonth() + monthRange;
    const day = date.getDate();

    const curDate = new Date(year, month, day);
    const curYear = curDate.getFullYear();
    const curMonth = curDate.getMonth();

    const curMonthRange = (curYear * 12 + curMonth) - (year * 12 + month);

    if (curMonthRange !== 0) { // 月份冒泡处理
        return roundTimeToMonthEnd(new Date(year, month));
    }

    return new Date(year, month, day);
};

export const dateWeekday = (timeStamp, {isAllWeekName = false} = {}) => {
    if (!timeStamp) return '';

    const targetWeekday = moment(timeStamp).weekday();
    const diffValue = getDayStartTimeStamp(timeStamp) - getDayStartTimeStamp(new Date().getTime());
    const oneDayStamp = 86400000;

    if (!isAllWeekName) {
        if (!diffValue) {
            return '今天';
        }

        if (diffValue === oneDayStamp) {
            return '明天';
        }
    }

    const weekMapList = ['天', '一', '二', '三', '四', '五', '六'];

    return `周${weekMapList[targetWeekday]}`;
};

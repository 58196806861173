/**
 * @Author: xymo
 * @Date: 2018-07-31
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:12
 */

import {on, off, isChildrenDOM, isTouchScreenFunc} from 'ssr-common/util/dom/dom';

const isBindingFunction = binding => typeof binding.value === 'function';

const genDocumentHandler = (el, hook) => event => {
    const {target} = event;
    if (el === target || isChildrenDOM(el, target)) return;
    hook();
};

const click = isTouchScreenFunc() ? 'touchstart' : 'click';

export default {
    bind(el, binding, vnode) {
        if (!isBindingFunction(binding)) return;
        vnode['@@click-outside'] = genDocumentHandler(el, binding.value);
        on(document, click, vnode['@@click-outside']);
    },

    unbind(el, binding, vnode) {
        off(document, click, vnode['@@click-outside']);
    }
};

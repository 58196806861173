/**
 * @author panezhang
 * @date 2018/6/28-19:24
 * @file hook-ready
 */

export const hookReadyClientMixin = {
    methods: {
        scrollReady() { // 用于通知 vue router 的 scroll behavior 页面已经 ready，可以执行 scroll behavior，页面可以覆盖
            return Promise.resolve();
        }
    }
};

/**
 * @Author: panezhang
 * @Date: 2017/12/11-下午1:33
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 16:16:41
 */
/* global
 __DEBUG__, __VUE_DEBUG__, __STAGE__
  __CHIDORI_API_HOST__, __CHIDORI_HOST__, __MAYURI_API_HOST__,
  __WEB_HOST__, RUN_PORT
*/

export const DEBUG = __DEBUG__;
export const VUE_DEBUG = __VUE_DEBUG__;
export const {VUE_ENV} = process.env;

export const CHIDORI_API_HOST = __CHIDORI_API_HOST__;
export const CHIDORI_HOST = __CHIDORI_HOST__;

export const MAYURI_API_HOST = __MAYURI_API_HOST__;

export const WEB_HOST = __WEB_HOST__;

const VUE_ENV_TYPE = {
    CLIENT: 'client',
    SERVER: 'server'
};

export const isServer = () => VUE_ENV === VUE_ENV_TYPE.SERVER;
export const isClient = () => VUE_ENV === VUE_ENV_TYPE.CLIENT;

export const STAGE = __STAGE__;
export const isProd = () => /prod/.test(STAGE);
export const isTest = () => /test/i.test(STAGE);
export const isOverseasStage = () => /overseas|indo|mex|india|brazil|phi/i.test(STAGE);

export const getEurekaHost = () =>
    (typeof window !== 'undefined' && window.location.origin)
    || `http://127.0.0.1:${RUN_PORT}`;

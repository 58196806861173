/*
 * @Author: liuzhenkun
 * @Date: 2018-07-10 13:33:36
 * @Last Modified by: liuzhenkun
 * @Last Modified time: 2020-09-03 16:48:16
 */

import {isServer} from '../../constant/config';
import {parse} from './ua';

// WARNING 不支持 ssr
export default parse(isServer() ? '' : window.navigator.userAgent);

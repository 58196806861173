/*
 * @Author: liuzhenkun
 * @Date: 2018-07-10 11:36:26
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-01-19 17:24:04
 */

import axios from 'axios';
import Vue from 'vue';

axios.objToFormData = obj => {
    if (!obj) return obj;

    const formData = new FormData();
    Object.keys(obj).forEach(key => {
        const currData = obj[key];
        if (currData.constructor === Array || currData.constructor === Object) {
            formData.append(key, JSON.stringify(currData));

            return;
        }

        formData.append(key, currData);
    });

    return formData;
};

axios.formData = (url, obj, options) => {
    const formData = axios.objToFormData(obj);
    if (!formData) return formData;

    return axios.post(url, formData, options);
};

export default Vue.prototype.$http = axios;

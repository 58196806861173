/**
 * @Author: KylesLight
 * @Date: 24/05/2018-10:19 PM
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 16:35:27
 */

export default {
    inViewport(el) {
        const rect = el.getBoundingClientRect();

        return !(rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight);
    },

    bind(el, binding) {
        const reachClassName = binding.value;

        el.classList.add('before-enter');
        el.$onScroll = () => {
            if (binding.def.inViewport(el)) {
                el.classList.add(reachClassName);
                el.classList.remove('before-enter');
                binding.def.unbind(el, binding);
            }
        };

        document.addEventListener('scroll', el.$onScroll);
    },

    inserted(el) {
        el.$onScroll();
    },

    unbind(el) {
        document.removeEventListener('scroll', el.$onScroll);
        delete el.$onScroll;
    }
};

/*
 * @Author: KylesLight
 * @Date: 2017-12-15 18:54:59
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-10-29 00:12:40
 */

import Vue from 'vue';

import {VUE_DEBUG, isClient, isServer} from 'ssr-common/constant/config';

Vue.config.devtools = VUE_DEBUG;
Vue.config.silent = !VUE_DEBUG;
Vue.config.errorHandler = (err, vm, info) => {
    if (isServer()) {
        throw err;
    }
};

/**
 * @Author: panezhang
 * @Date: 2018/6/2-11:39
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-10-12 14:02:26
 */
/* global __INITIAL_STATE__ */

import Vue from 'vue';

import {fetchComponentsAsyncData, reuseServerState, mixin as asyncDataMixin} from './feature/async-data';
import {createClientMixin as createHeadClientMixin} from './feature/head';
import {hookReadyClientMixin} from './feature/hook-ready';
import {registerComponentsStoreModules, mixin as storeModuleMixin} from './feature/store-module';
import {createClientMixin as createTitleClientMixin} from './feature/title';

export const createClientEntry = ({createApp, rootTitle, el = '#app', noStageTitle} = {}) => {
    Vue.mixin(asyncDataMixin);
    Vue.mixin(createHeadClientMixin());
    Vue.mixin(hookReadyClientMixin);
    Vue.mixin(storeModuleMixin);
    Vue.mixin(createTitleClientMixin({rootTitle, noStageTitle}));

    const context = __INITIAL_STATE__.$context;
    delete __INITIAL_STATE__.$context;
    const {app, router, store} = createApp(context);

    router.onReady(() => {
        const matchedComponents = router.getMatchedComponents();
        registerComponentsStoreModules(store, matchedComponents);
        reuseServerState(store);

        // Add router hook for handling asyncData.
        // Doing it after initial route is resolved so that we don't double-fetch the data that we already have.
        // Using router.beforeResolve() so that all async components are resolved.
        router.beforeResolve((to, from, next) => {
            const matched = router.getMatchedComponents(to);

            // 是否重新获取数据，交由组件自己判断，有些组件确实需要重新获取
            // const prevMatched = router.getMatchedComponents(from);
            // let diffed = false;
            // const activated = matched.filter(
            //     (component, idx) => diffed || (diffed = (prevMatched[idx] !== component))
            // );

            registerComponentsStoreModules(store, matched);
            fetchComponentsAsyncData(store, to, [app.$options, ...matched]).then(() => next()).catch(next);
        });

        app.$mount(el);
    });

    return {app, router, store, context};
};

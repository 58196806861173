/**
 * @Author: panezhang
 * @Date: 2018-06-01 20:12:27.415
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-10-30 11:03:45
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// import 'ssr-common/util/report';
import {initSentry} from 'ssr-common/util/sentry';
import {createClientEntry} from 'ssr-common/vue/ssr/entry-client';

import {createApp} from './main';

const {app, router} = createClientEntry({createApp});
initSentry({
    app,
    router,
    dsn: 'https://0eb97add0a58a933cca0e75f0a5a73aa@sentry.fintopia.tech/83'
});

if (module.hot) {
    module.hot.accept();
}

/**
 * @Author: panezhang
 * @Date: 2017/12/19-下午2:45
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:12
 */
/* global __INITIAL_STATE__ */

// server 端渲染时需
// client 端路由变化时
export const fetchComponentsAsyncData = (store, route, Components, cookie) => {
    const asyncDataHooks = Components.map(component => component.asyncData).filter(x => x);
    if (!asyncDataHooks.length) {
        return Promise.resolve();
    }

    return Promise.all(asyncDataHooks.map(hook => hook({store, route, cookie})));
};

// client 端混合时
export const reuseServerState = store => (__INITIAL_STATE__ && store.replaceState(__INITIAL_STATE__));

// client 端路由更新时（由于参数改变时不会触发 vue-router 的 beforeResolve
export const mixin = {
    beforeRouteUpdate(to, from, next) {
        const {asyncData} = this.$options;
        if (!asyncData) {
            next();

            return;
        }

        asyncData({store: this.$store, route: to}).then(() => next()).catch(next);
    }
};

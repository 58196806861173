/**
 * @author panezhang
 * @date 2017/12/19-下午2:46
 * @file title
 * 提供一个 title 的钩子
 */
/* eslint-disable consistent-this */

import {STAGE, isProd} from 'ssr-common/constant/config';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import {isWebview, isComplaint} from 'ssr-common/util/webview/ua';
import {hasInstance} from 'ssr-common/vue/component/yqg-title/util';

function getSubTitle(vm) {
    const {title} = vm.$options;
    if (title) {
        return typeof title === 'function' ? title.call(vm) : title;
    }

    return '';
}

export function getTitle(rootTitle, subTitle, noStageTitle = []) {
    let title;

    if (!subTitle || !rootTitle) {
        title = subTitle || rootTitle;
    } else {
        title = `${subTitle} - ${rootTitle}`;
    }

    if (!isProd() && !noStageTitle.includes(STAGE)) {
        title = `(${STAGE}) ${title}`;
    }

    return title;
}

function setServerTitle(vm, {rootTitle, noStageTitle}) {
    if (vm.$options.title && !hasInstance()) {
        if (isWebview(vm.$ssrContext.userAgent) || isComplaint(vm.$ssrContext.userAgent)) {
            vm.$ssrContext.title = getSubTitle(vm);
        } else {
            vm.$ssrContext.title = getTitle(rootTitle, getSubTitle(vm), noStageTitle);
        }
    }
}

function setClientTitle(vm, {rootTitle, noStageTitle}) {
    if (vm.$options.title && !hasInstance()) {
        if (isWebview(window.navigator.userAgent)) {
            const title = getSubTitle(vm);
            document.title = title;
            WebviewInterface.setDocumentTitle(title);
        } else {
            document.title = getTitle(rootTitle, getSubTitle(vm), noStageTitle);
        }
    }
}

function unsetClientTitle({rootTitle}) {
    if (rootTitle && !isWebview(window.navigator.userAgent)) {
        document.title = rootTitle;
    }
}

export const createServerMixin = (config = {}) => ({
    created() { // 这里不能用 async function，因为在 server 端不能保证执行完毕
        setServerTitle(this, config);
    }
});

export const createClientMixin = (config = {}) => ({
    mounted() {
        setClientTitle(this, config);
    },

    updated() {
        setClientTitle(this, config);
    },

    beforeRouteLeave(to, from, next) {
        unsetClientTitle(config);
        next();
    }
});
